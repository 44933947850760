import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios for making HTTP requests
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import icon3 from "../Images/icon3.png";
import carasol1 from "../Images/carasol1.png";
import carasol2 from "../Images/carasol2.png";
import carasol3 from "../Images/carasol3.png";
import whyuse1 from "../icons/whyUseIcon1.png";
import whyuse2 from "../icons/whyUseIcon2.png";
import whyuse3 from "../icons/whyUseIcon3.png";
import ImageSlider from "../Components/ImageSlider";
import object from "../Images/object.png";
import FlightSearchForm from "../Components/FlightSearchForm";
import FlightSearchData from "../Components/FlightSearchData";

const Flight = () => {
  const [showForm, setShowForm] = useState(true);
  const [showMultiCityForm, setShowMultiCityForm] = useState(false);
  const [departureOptions, setDepartureOptions] = useState([]);
  const [loadingDepartures, setLoadingDepartures] = useState(true);

  useEffect(() => {
    // Fetch departure options when component mounts
    fetchDepartureOptions();
  }, []);

  const fetchDepartureOptions = async () => {
    try {
      const response = await axios.get("192.168.18.25:8000/flight/airports?"); // Replace "https://api.example.com/departures" with your actual API endpoint
      setDepartureOptions(response.data);
      setLoadingDepartures(false);
    } catch (error) {
      console.error("Error fetching departure options:", error);
      setLoadingDepartures(false);
    }
  };
  const airports = [
    {
      api_id: "arp_lhe_pk",
      iata_city_code: "LHE",
      city_name: "Lahore",
      icao_code: "OPLA",
      iata_country_code: "PK",
      iata_code: "LHE",
      latitude: 31.521077,
      longitude: 74.407111,
      city: null,
      time_zone: "Asia/Karachi",
      name: "Allama Iqbal International Airport",
    },
    {
      api_id: "arp_pew_pk",
      iata_city_code: "PEW",
      city_name: "Peshawar",
      icao_code: "OPPS",
      iata_country_code: "PK",
      iata_code: "PEW",
      latitude: 33.992877,
      longitude: 71.515714,
      city: null,
      time_zone: "Asia/Karachi",
      name: "Bacha Khan International Airport",
    },
    {
      api_id: "arp_bhv_pk",
      iata_city_code: "BHV",
      city_name: "Bahawalpur",
      icao_code: "OPBW",
      iata_country_code: "PK",
      iata_code: "BHV",
      latitude: 29.349086,
      longitude: 71.714577,
      city: null,
      time_zone: "Asia/Karachi",
      name: "Bahawalpur Airport",
    },
  ];

  const handleRadioChange = (event) => {
    setShowForm(
      event.target.value === "one_way" || event.target.value === "return"
    );
    setShowMultiCityForm(event.target.value === "multi_city");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add logic to handle form submission
  };

  return (
    <>
      <section className="bg-dark search-form-section pt-5">
        <div className="container p-4 px-3 px-md-4 px-lg-5">
          <FlightSearchForm/>
          <p className="text-white pt-5">
              Subscribe Ticketistan and get 2% Off on a return ticket
            </p>
          <div className="carasol">
            <ImageSlider />
          </div>
        </div>
      </section>
      {/* <FlightSearchData/> */}

      <section className="quick-links-section my-5 d-md-none d-lg-block">
        <div className="container px-3 px-md-4 px-lg-5 ">
          <div className="row g-3 justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="#mobile-app ">
                  <div className="d-flex align-items-center ">
                    <div className="side-icon">
                      <img src={icon1} />
                    </div>
                    <div className="content ms-3 ">
                      <h5>Download Ticketestan App</h5>
                      <p className="mb-0">Great detais on our mobile app</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/contact-us">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon2} />
                    </div>
                    <div className="content ms-3">
                      <h5>Help Center</h5>
                      <p className="mb-0">Search on mobile apps with ease</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="quick-link-box h-100 border p-2 rounded">
                <a href="/user/bookings">
                  <div className="d-flex  align-items-center">
                    <div className="side-icon">
                      <img src={icon3} />
                    </div>
                    <div className="content ms-3">
                      <h5>Manage Bookings</h5>
                      <p className="mb-0">Explore and bookings hassel-frees</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      

      <section className="travel-partners-section">
        <div className="container pt-md-4 pb-5 pt-lg-0 px-3 px-md-4 px-lg-5">
          <p className="h2 font-weight-600 mb-1">Latest Offers</p>
          <p className="lead h5 font-weight-400">
            Ticketestan furnishes affordable & comfortable services across
            Pakistan.{" "}
          </p>
          <div className="carasol">
            <img src={carasol1} />
            <img src={carasol2} />
            <img src={carasol3} />
          </div>
        </div>
      </section>

      <section className="why-use-ticketestan bg-blue ">
        <div className="container py-5 px-3 px-md-4 px-lg-5">
          <div className="row">
            <div className="col-lg-8">
              <p className="h2 fs-5 font-weight-600  mb-1 mt-3">
                Why Use Ticketestan?
              </p>
              <p className="lead h5 font-weight-400 ">
                We furnish ample of deals in Pakistan.
              </p>
            </div>
          </div>
          <div className="row g-3 justify-content-center mt-2">
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse1} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse2} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex p-2">
                <div className="side-icon">
                  <img src={whyuse3} alt="whyuse" />
                </div>
                <div className="content ms-3">
                  <h5>More for Less</h5>
                  <p className="mb-0 ">
                    We offer e-tickets with exceptional discounted deals across
                    the country
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5 mb-3 px-3 px-md-4 px-lg-5">
          <p className="h2 font-weight-600 mb-3">Frequently Asked Questions</p>
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What types of events does Ticketistan.com offer?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Ticketistan.com provides a diverse selection of events,
                  including concerts,sports matches, theater performances and
                  more.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  How can I book tickets?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Booking is effortless!Simply use our streamlined process,
                  ensuring a hassle-free experience with justa few clicks.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Can I explore events internationally?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Yes! Ticketistan.com allows you to discover events both locally
                  andinternationally, offering a gateway to a global array of
                  experiences.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  Are there corporate packages available?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Absolutely! Elevate yourbusiness engagements with our
                  exclusive corporate packages, perfect forimpressing clients or
                  rewarding your team.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  Is my transaction secure?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Your security is paramount. Rest assured, Ticketistan.com
                  provides securetransactions for worry-free ticket purchases.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSix"
                  aria-expanded="false"
                  aria-controls="flush-collapseSix"
                >
                  How quickly will I receive confirmation and e-tickets?
                </button>
              </h2>
              <div
                id="flush-collapSix"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingSix"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Swiftly! Expect instantconfirmation and e-tickets directly to
                  your inbox, ensuring no waiting for yourkey to unforgettable
                  moments.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSiven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseSiven"
                  aria-expanded="false"
                  aria-controls="flush-collapseSiven"
                >
                  Can I book tickets using a mobile device?
                </button>
              </h2>
              <div
                id="flush-collapseSiven"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingSiven"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Certainly! Ticketistan.com offers a user-friendly mobile
                  platform,allowing you to book tickets on the go, anytime,
                  anywhere.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingEight">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseEight"
                  aria-expanded="false"
                  aria-controls="flush-collapseEight"
                >
                  Are there gift options available?
                </button>
              </h2>
              <div
                id="flush-collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingEight"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Absolutely! Gift theexperience with our customizable gift
                  options, perfect for any occasion andunlocking a world of live
                  entertainment.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingNine">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseNine"
                  aria-expanded="false"
                  aria-controls="flush-collapseNine"
                >
                  Are there any exclusive deals or discounts available?
                </button>
              </h2>
              <div
                id="flush-collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingNine"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Don't miss out! Keep an eyeon our Hot Offers for exclusive
                  deals and incredible discounts, making yournext experience
                  even more extraordinary.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTen">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTen"
                  aria-expanded="false"
                  aria-controls="flush-collapseTen"
                >
                  What if I have questions or need assistance?
                </button>
              </h2>
              <div
                id="flush-collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTen"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  Our dedicated customer careteam is ready to assist you. If you
                  have any questions or need help, feel freeto reach out we're
                  here to ensure your Ticketistan.com experience istop-notch.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="mobile-app" className="mobile-app-section pt-5">
        <div className="container px-0 px-md-4 px-lg-5">
          <div className="mobile-app px-3 px-lg-5 rounded-sm rounded-1">
            <div className="row">
              <div className="col-lg-7 pt-5 pt-lg-0 align-self-center">
                <p className="h2 font-weight-600  mb-4 pt-5">
                  Download our app
                </p>
                <p className="lead h5 font-weight-400">
                  Get amazing deals and bundles on Ticketestan Application{" "}
                </p>
                <div className="d-flex mt-4 mb-5">
                  <div className="me-1">
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/ios-app-light.bac60b30.svg"
                        alt="Download IOS App"
                      />
                    </a>
                  </div>
                  <div className="ms-1">
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="img-fluid"
                        src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/android-app-light.031b1575.svg"
                        alt="Download Android App"
                      />
                    </a>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row g-2">
                    <div className="col-md-8">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter your phone number"
                        required
                        minLength="11"
                        maxLength="11"
                      />
                    </div>
                    <div className="col-md-4">
                      <button
                        type="submit"
                        className="btn btn-primary-light btn-clr shadow"
                      >
                        Get Download Link
                      </button>
                    </div>
                    <div className="col-12">
                      <p className="mb-0 font-weight-400 pb-5">
                        {" "}
                        Your privacy is important for us. Check our{" "}
                        <a
                          href="/"
                          className="link-dark text-decoration-underline"
                        >
                          privacy policy
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="col-lg-5 pt-5 d-flex align-self-end justify-content-center justify-content-lg-end">
              <img className="img-fluid" src="https://storage.googleapis.com/bookmepk/static/custom/V5/dist/bookme-app.ea7603cd.svg" alt="Download Bookme App" />
            </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5">
        <div className="container px-3 px-md-4 px-lg-5">
          <p className="h2 font-weight-600 mb-3">Top Flights</p>
          <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingeleven">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseeleven"
                  aria-expanded="false"
                  aria-controls="flush-collapseeleven"
                >
                  Top Flights
                </button>
              </h2>
              <div
                id="flush-collapseeleven"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingeleven"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                <ul className="list-container ">
            <li className="list-item">
              <a href="">Lahore to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Skardu Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Skardu Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Skardu Flights</a>
            </li>
            <li className="list-item">
              <a href="">Multan to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Sialkot to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Jeddah to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Jeddah to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Istanbul Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Istanbul Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Istanbul Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to Baku Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Baku Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Baku Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to New York Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to New York Flights</a>
            </li>
            <li className="list-item">
              <a href="">Lahore to New York Flights</a>
            </li>
            <li className="list-item">
              <a href="">Chicago to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Chicago to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Chicago to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Dallas to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">New York to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">New York to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">Dallas to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Dallas to Lahore Flights</a>
            </li>
            <li className="list-item">
              <a href="">New York to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Multan Flights</a>
            </li>
            <li className="list-item">
              <a href="">Multan To Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Peshawar to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Faisalabad to Karachi Flights</a>
            </li>
            <li className="list-item">
              <a href="">Islamabad to Quetta Flights</a>
            </li>
            <li className="list-item">
              <a href="">Quetta to Islamabad Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Peshawar Flights</a>
            </li>
            <li className="list-item">
              <a href="">Karachi to Faisalabad Flights </a>
            </li>
            <li className="list-item">
              <a href="">Dubai to Sialkot Flights </a>
            </li>
            <li className="list-item">
              <a href="">Quetta to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Peshawar to Dubai Ticket Price</a>
            </li>
            <li className="list-item">
              <a href="">Dubai to Multan Flights</a>
            </li>
          </ul>
                </div>
              </div>
            </div>
          
        </div>
      </section>

      <section>
        <div className="container pb-5  px-3 px-md-4 px-lg-5">
        <div class="container mt-5">
  <div class="row">
    <div class="col-12 p-0">
    <hr class="mb-4"/>
      <h2 class="mb-4 fs-5 font-weight-600">Looking for Flight Booking Online?</h2>
      <p class="fs-6">
      Ticketestan provides a great opportunity for arrangement of yourair-affairs more easy. Your trust is more than anything else for us. Pakistan’slargest online ticketing as per your dreams which may not provide anywhereelse. Come and find complete solution of your online booking globally withoutany complication.
      </p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 font-weight-600">Fly Well</h2>
      <p class="fs-6">
      Pandemic COVID-19 causedhuge uncertainty around the world but Ticketestan has alternative options toprotect your booking and lay out online services more booking. Your trip of anykind important for us, therefore, you can schedule your flights online throughTicketestan without any break.
      </p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 font-weight-600">Manage Your Airline Booking</h2>
      <p class="fs-6">
      Peace of mind means changing your travel dates as often as you need. We understand your plans change, and that is why Ticketistan offers a fee-free policy to change your booking details before 48 hours of your flight. Now book your online flight ticket with Ticketistan and relax knowing that you are in safe hands.
      </p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 font-weight-600">Fly Wherever You Want</h2>
      <p class="fs-6">
      Our online system has more satisfaction for its customers both domestic and international flights.
      </p>
      <h3 className="fs-6">Our International destinations include:</h3>
  <ul>
    <li>Dubai</li>
    <li>Turkey</li>
    <li>Maldives</li>
    <li>United Kingdom</li>
  </ul>

  <h3 className="fs-6">Our International destinations include:</h3>
  <ul>
    <li>Karachi</li>
    <li>Islamabad</li>
    <li>Lahore</li>
    <li>Multan</li>
    <li>Sialkot</li>
  </ul>

      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0">
    
      <h2 class="mb-4 fs-5 font-weight-600">Free Travel Insurance</h2>
      <p class="fs-6">
      Ticketestan provides free travel insurance for domestic flights and yielding for international flights insurance as well as you wish. Click for more detail…

      </p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 ">
    
      <h2 class="mb-4 fs-5 font-weight-600">How to Book Flight Tickets Online ?</h2>
      <p class="fs-6">Following steps to book your airline ticket through Ticketestan:</p>
      <ol>
        <li>Go to the flights menu.</li>
        <li>Enter your travel details.</li>
        <li>Select your desired airline.</li>
        <li>Provide your contact details.</li>
        <li>Checkout your purchase.</li>
      </ol>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 ">
    <h2 class="mb-4 fs-5 font-weight-600">Ticketestan – OnlineTickets Booking in Pakistan</h2>
      <p class="fs-6">If you are enthusiastic fortravelling and find difficulty in comfortable flights tickets along with movie tickets, hotel bookings across Pakistan. In this connection visit our online-ticketing system where you can find solutions of all problems hassle free. Moreover, we provide online discount with our in-app bundles.</p>
      <hr class="my-4"/>
    </div>
  </div>

  <div class="row">
    <div class="col-12 p-0 ">
    <h2 class="mb-4 fs-5 font-weight-600">Travel through Pakistan</h2>
      <p class="fs-6">To aval mega discount on bus and domestic flights tickets, Ticketestancan browse for you with more ease and comfort</p>
      
    </div>
  </div>


</div>
        </div>
      </section>
    </>
  );
};

export default Flight;
