import './App.css';
import Header from './Components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Flight from './Pages/Flight.jsx';
import Bus from './Pages/Bus.jsx';
import Trains from './Pages/Trains.jsx';
import Movies from './Pages/Movies.jsx';
import CarRental from './Pages/CarRental.jsx';
import Events from './Pages/Events.jsx';
import Tours from './Pages/Tours.jsx';
import Sidebar from './Components/Sidebar.js';
import SignUpForm from './Pages/SignUpForm.jsx';
import LoginForm from './Pages/LoginForm.jsx';
import Footer from './Components/Footer.jsx';


function App() {
  return (
    <>
    <Header/>
    <BrowserRouter>
    <Sidebar >
    <Routes>
      <Route path='/' element={<Flight/>}/>
      <Route path='/flight' element={<Flight/>}/>
      <Route path='/bus' element={<Bus/>}/>
      <Route path='/train' element={<Trains/>}/>
      <Route path='/movies' element={<Movies/>}/>
      <Route path='/carRental' element={<CarRental/>}/>
      <Route path='/events' element={<Events/>}/>
      <Route path='/tours' element={<Tours/>}/>
      <Route path='/signUp' element={<SignUpForm/>}/>
      <Route path='/login' element={<LoginForm/>}/>
    </Routes>
    <Footer/>
    </Sidebar>  
    </BrowserRouter>
   
    
    </>
  );
}

export default App;

  // return (
  //   <>
  //     <Header/>
  //     <BrowserRouter>
  //       <Sidebar>
  //         <Routes>
  //           <Route path='/' element={<Flight/>}/>
  //           <Route path='/flight' element={<Flight/>}/>
  //           <Route path='/bus' element={<Bus/>}/>
  //           <Route path='/train' element={<Trains/>}/>
  //           <Route path='/movies' element={<Movies/>}/>
  //           <Route path='/carRental' element={<CarRental/>}/>
  //           <Route path='/events' element={<Events/>}/>
  //           <Route path='/tours' element={<Tours/>}/>

  //           {/* Footer ko Footer component ke andar rakha gaya hai */}
  //           <Route path={['/flight', '/bus', '/train', '/movies', '/carRental', '/events', '/tours']} element={<>
  //             <Footer/>
  //           </>}/>

  //           <Route path='/signUp' element={<SignUpForm/>}/>
  //           <Route path='/login' element={<LoginForm/>}/>
  //         </Routes>
  //       </Sidebar>  
  //     </BrowserRouter>
  //   </>
  // );


