import { FaPlane, FaBus, FaTrain, FaFilm, FaCar, FaHotel, FaCalendarAlt, FaMapSigns,  } from 'react-icons/fa';

export const menuItem=[
    {
        path:"/flight",
        name:"Flight",
        icon:<FaPlane/>
    },
    {
        path:"/bus",
        name:"Bus",
        icon:<FaBus/>
    },
    {
        path:"/trains",
        name:"Trains",
        icon:<FaTrain/>
    },
    {
        path:"/movies",
        name:"Movies",
        icon:<FaFilm/>
    },
    {
        path:"/carRental",
        name:"CarRental",
        icon:<FaCar/>
    },
    {
        path:"/hotel",
        name:"Hotel",
        icon:<FaHotel/>
    },
    {
        path:"/events",
        name:"Events",
        icon:<FaCalendarAlt/>
    },
    {
        path:"/tours",
        name:"Tours",
        icon:<FaMapSigns/>
    }
]

