import React, { useState } from 'react';
import './FlightSearchForm.css'

function FlightSearchForm() {
  const [flightType, setFlightType] = useState('oneWay');
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("adults");
  const [currentDate, setCurrentDate] = useState(getCurrentDate());

  function getCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  const handleDateChange = (e) => {
    setCurrentDate(e.target.value);
  };

  
  
  
  
  const decreaseCount = (type,e) => {
    e.preventDefault();
    e.stopPropagation();
    switch (type) {
      case 'adult':
        if (adultCount > 1) setAdultCount(adultCount - 1);
        break;
      case 'child':
        if (childCount > 0) setChildCount(childCount - 1);
        break;
      case 'infant':
        if (infantCount > 0) setInfantCount(infantCount - 1);
        break;
      default:
        break;
    }
  };

    const increaseCount = (type,e) => {
      e.preventDefault();
      e.stopPropagation();
      switch (type) {
        case 'adult':
          if (adultCount < 3) setAdultCount(adultCount + 1);
          break;
        case 'child':
          if (childCount < 3) setChildCount(childCount + 1);
          break;
        case 'infant':
          if (infantCount < 3) setInfantCount(infantCount + 1);
          break;
        default:
          break;
      }
    };

  const handleFlightTypeChange = (e) => {
    setFlightType(e.target.value);
    setSelectedOption(e.target.value);
  };

  const getPassengerCount = () => {
    switch (selectedOption) {
      case 'adults':
        return adultCount;
      case 'children':
        return childCount;
      case 'infants':
        return infantCount;
      default:
        return 0;
    }
  };
  const totalPassengers = adultCount + childCount + infantCount;

  return (
    <div className="search-form">
      <p className="h2 text-white font-weight-600 mb-3">Search for Flights</p>
      <p className="lead h5 text-white font-weight-400">Find the best and most affordable flights across the globe </p>
      <form id="flightSearch" className="mt-4">
        <div className="row gx-2 gy-3 position-relative">
          <div className="col-md-6 mb-3">
            <div className="form-check form-check-inline">
              <input 
                className="form-check-input bg-transparent border border-white" 
                type="radio" 
                name="type" 
                id="one_way" 
                value="oneWay" 
                checked={flightType === "oneWay"}
                onChange={handleFlightTypeChange}
              />
              <label className="form-check-label text-white" htmlFor="one_way">One Way</label>
            </div>
            <div className="form-check form-check-inline">
              <input 
                className="form-check-input bg-transparent border border-white" 
                type="radio" 
                name="type" 
                id="roundway" 
                value="roundTrip" 
                checked={flightType === "roundTrip"}
                onChange={handleFlightTypeChange}
              />
              <label className="form-check-label text-white" htmlFor="roundway">Round Trip</label>
            </div>
            <div className="form-check form-check-inline">
              <input 
                className="form-check-input bg-transparent border border-white" 
                type="radio" 
                name="type" 
                id="multicity" 
                value="multiCity" 
                checked={flightType === "multiCity"}
                onChange={handleFlightTypeChange}
              />
              <label className="form-check-label text-white" htmlFor="multicity">Multi-City</label>
            </div>
          </div>
        </div>

        {/* One Way Form */}
        {flightType === "oneWay" && (
            <div className="row gx-2 gy-3 mb-3 position-relative">
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                  <input
                    name="departureOneWay"
                    type="text"
                    className="form-control departure-icon"
                    placeholder="Departure"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Departure</span> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                  <input
                    name="arrivalOneWay"
                    type="text"
                    className="form-control arrival-icon"
                    placeholder="Arrival"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Arrival</span> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6" id="departure-date">
                <div className="input-group position-relative">
                  <input
                    type="date"
                    className="form-control date-icon"
                    placeholder="Departure Date"
                    required
                    autoComplete="off"
                    value={currentDate}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          
        )}

        {/* Round Trip Form */}
        {flightType === "roundTrip" && (
          <div>
            <div className="row gx-2 gy-3 mb-3 position-relative">
              <div className="col-lg-3 col-md-6">
                <div className="input-group">
                  <input
                    name="departureRoundTrip"
                    type="text"
                    className="form-control departure-icon"
                    placeholder="Departure"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Departure</span> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="input-group">
                  <input
                    name="arrivalRoundTrip"
                    type="text"
                    className="form-control arrival-icon"
                    placeholder="Arrival"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Arrival</span> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6" id="departure-date">
                <div className="input-group position-relative">
                  <input
                    type="date"
                    className="form-control date-icon"
                    placeholder="Departure Date"
                    required
                    autoComplete="off"
                    value={currentDate}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6" id="arrival-date">
                <div className="input-group position-relative">
                  <input
                    type="date"
                    className="form-control date-icon"
                    placeholder="Arrival Date"
                    required
                    autoComplete="off"
                    value={currentDate}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Multi-City Form */}
        {flightType === "multiCity" && (
          <div>
            <div className="row gx-2 gy-3 mb-3 position-relative">
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                  <input
                    name="departureMultiCity"
                    type="text"
                    className="form-control departure-icon"
                    placeholder="Departure"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Departure</span> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="input-group">
                  <input
                    name="arrivalMultiCity"
                    type="text"
                    className="form-control arrival-icon"
                    placeholder="Arrival"
                    aria-controls="listbox-null"
                    required
                  />
                  {/* <span className="multiselect__placeholder">Arrival</span> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6" id="departure-date">
                <div className="input-group position-relative">
                  <input
                    type="date"
                    className="form-control date-icon"
                    placeholder="Departure Date"
                    required
                    autoComplete="off"
                    value={currentDate}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Departure, Arrival, Date, etc. Fields */}
        <div className="row gx-2 gy-3">
          <div className="col-lg-5 col-md-6">
            <div className="dropdown">
              {/* Passengers input */}
              <input
                type="text"
                className="form-control"
                placeholder="No of Passengers"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={`Total Passengers: ${totalPassengers}`}
              />
              <div className="dropdown-menu p-3 w-100" id="passengers">
                {/* Passengers dropdown */}
                <div className="row g-3 align-items-center">
    {/* Adults */}
    <div className="col-4">
        <label htmlFor="adult" className="mb-0">Adult(s)</label>
    </div>
    <div className="col-8 d-flex justify-content-end">
        <button type="button" className="btn btn-outline-secondary" onClick={(e) => decreaseCount('adult', e)}>
            <i className="fa fa-minus text-gray-500"></i>
        </button>
        <input type="number" className="form-control text-center w-25" id="adultCount" value={adultCount} readOnly />
        <button type="button" className="btn btn-outline-secondary " onClick={(e) => increaseCount('adult', e)}>
            <i className="fa fa-plus text-gray-500"></i>
        </button>
    </div>

    {/* Children */}
    <div className="col-4">
        <label htmlFor="child" className="mb-0">Children</label>
    </div>
    <div className="col-8 d-flex justify-content-end">
        <button type="button" className="btn btn-outline-secondary" disabled={childCount === 0} onClick={(e) => decreaseCount('child', e)}>
            <i className="fa fa-minus text-gray-500"></i>
        </button>
        <input type="number" className="form-control text-center  w-25" id="childCount" max="3" value={childCount} readOnly />
        <button type="button" className="btn btn-outline-secondary" onClick={(e) => increaseCount('child', e)}>
            <i className="fa fa-plus text-gray-500"></i>
        </button>
    </div>

    {/* Infants */}
    <div className="col-4">
        <label htmlFor="infant" className="mb-0">Infant(s)</label>
    </div>
    <div className="col-8 d-flex justify-content-end">
        <button type="button" className="btn btn-outline-secondary" disabled={infantCount === 0} onClick={(e) => decreaseCount('infant',e)}>
            <i className="fa fa-minus text-gray-500"></i>
        </button>
        <input type="number" className="form-control text-center  w-25" id="infantCount" max="3" value={infantCount} readOnly />
        <button type="button" className="btn btn-outline-secondary" onClick={(e) => increaseCount('infant', e)}>
            <i className="fa fa-plus text-gray-500"></i>
        </button>
    </div>

    {/* Done Button */}
    <div className="col-12">
        <button className="btn btn-primary w-100 btn-clr">Done</button>
    </div>
</div>

    
                {/* ... (Keep the same as in your original code) */}
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            {/* Flight class selector */}
            <select className="form-control" id="class" defaultValue="Economy">
              <option value="">Class</option>
              <option value="Economy">Economy</option>
              <option value="PremiumEconomy">Premium Economy</option>
              <option value="Business">Business</option>
            </select>
          </div>
          <div className="col-md-12 col-lg-2">
            <button type="submit" className="btn btn-secondary text-white w-100 btn-clr">
            <i className="bi bi-search pe-2"></i>
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FlightSearchForm;
