import React,{useState} from 'react'
import { FaPlane, FaBus, FaTrain, FaFilm, FaCar, FaHotel, FaCalendarAlt, FaMapSigns, FaBars } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import logo from './Logo.png'
import './Sidebar.css'


const menuItem=[
    {
        path:"/flight",
        name:"Flight",
        icon:<FaPlane/>
    },
    {
        path:"/bus",
        name:"Bus",
        icon:<FaBus/>
    },
    {
        path:"/trains",
        name:"Trains",
        icon:<FaTrain/>
    },
    {
        path:"/movies",
        name:"Movies",
        icon:<FaFilm/>
    },
    {
        path:"/carRental",
        name:"CarRental",
        icon:<FaCar/>
    },
    {
        path:"/hotel",
        name:"Hotel",
        icon:<FaHotel/>
    },
    {
        path:"/events",
        name:"Events",
        icon:<FaCalendarAlt/>
    },
    {
        path:"/tours",
        name:"Tours",
        icon:<FaMapSigns/>
    }
]


const Sidebar = ({children}) => {
    const [isOpen,setOpen]=useState(true);
    const toggle =()=>setOpen(!isOpen);
   
  return (
    <div className='container1'>
        <div style={{width:isOpen ? "250px" : "50px"}} className='sidebar d-none d-sm-block'>
            {/* <div className='top_section'>
            <div  className='bars'><FaBars onClick={toggle}/></div>
                <img style={{display:isOpen ? "block" : "none"}} src={logo} alt="Logo" className="logo" />
            </div> */}
            {
                menuItem.map((item,index)=>(
                    <NavLink to={item.path} key={index} className="link" activeClassName="active">
                        <div className='icon'>{item.icon}</div>
                        <div style={{display:isOpen ? "block" : "none"}} className='link_text'>{item.name}</div>
                    </NavLink>
                ))
            }
        </div>
        <main>{children}</main>
    </div>
  )
}

export default Sidebar