import React,{useState} from 'react';
import './Header.css'; // Assuming you have a CSS file for styling
import logo from './Logo.png';
import {FaBars } from 'react-icons/fa';
import icon from './Group 1.png'
import menuItem from '../data/sidebar.js'



const Header = () => {
  const [isOpen,setOpen]=useState(true);
  const toggle =()=>setOpen(!isOpen);
  return (
    <>
    <header className="bg-white pb-1 px-0 " id="header">
    <div className="alert ticketistanbro-header align-text-bottom border-0 rounded-0 font-weight-500 text-center custom-primary-background alert-dismissible fade show lh-base py-2 mb-0 text-white text-size-sm" role="alert">
    
    <div className="d-flex flex-wrap align-items-center justify-content-end ">
        
        {/* WhatsApp Button */}
        <a href="https://wa.me/message/https://wa.me/+923041110636?text=Hello%20Ticketistan%20Team%2C%20I%20need%20help%20booking%20a%20flightVJDZMED22Z4SJ1" target="_blank" rel="noopener noreferrer" className="text-white me-2">
            <i className="fab fa-whatsapp"></i>
        </a>
        
        {/* WhatsApp Number */}
        <span className="me-4 text-white">+92 304 111 0636</span>
        
        {/* <a href="tel:+923041110636" className="text-white me-2">
            <i className="fas fa-phone-alt"></i>
        </a>
        
        <span className="text-white">+92 304 111 0636</span> */}
        
    </div>
</div>

     <nav className="navbar navbar-expand-lg my-1 bg-white">
  <div className="container-fluid px-3 px-md-4">
    <div className="d-flex">
      <div id="sidebarToggle" className='me-4 d-none d-md-block'>
        <div className='bars'><FaBars onClick={toggle}/></div>
      </div>
      <a href="/" className="navbar-brand" aria-label="ticketistan">
        <img style={{display: isOpen ? "block" : "none"}} src={logo} alt="Logo" className="logo" />
      </a>
    </div>
    <ul className="navbar-nav ml-auto align-items-center flex-row">
      <li className="nav-item d-none d-md-block">
        <a href="/contact-us" className="nav-link p-0 mx-3 text-dark">Help</a>
      </li>
      <li className="nav-item">
        <a href="/login" className="nav-link p-0 mx-3 text-dark">Login</a>
      </li>
      <li className="nav-item d-none d-md-flex">
        <a href="/signUp" className="nav-link text-white btn custom-primary-background px-4 ms-3">Sign up</a>
      </li>
    </ul>
  </div>
</nav>


    </header>

    {/* <section className="mobile-home bg-gray-100 py-4 px-2 h-100 d-block d-md-none bro" id="mobile_home">
      <div className="container mt-2">
        <div className="row g-3">
          <div className="col-4">
            <div className="card border-0 rounded p-3 py-4 text-center d-flex flex-column align-items-center">
              <svg className="mb-2" height="32" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              </svg>
              <a href="/buy-bus-tickets-online" className="stretched-link text-dark"> Bus </a>
            </div>
          </div>
          <div className="col-4">
            <div className="card border-0 rounded p-3 py-4 text-center d-flex flex-column align-items-center">
              <svg className="mb-2" height="32" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              </svg>
              <a href="/book-flights-online" className="stretched-link text-dark"> Airlines </a>
            </div>
          </div>
          <div className="col-4">
            <div className="card border-0 rounded p-3 py-4 text-center d-flex flex-column align-items-center">
              <svg className="mb-2" height="32" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              </svg>
              <a href="/train-tickets-booking-online" className="stretched-link text-dark"> Trains </a>
            </div>
          </div>
        </div>
      </div>
    </section> */}
    </>
  );
}

export default Header;



